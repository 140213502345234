import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ReceiptItemModel } from 'model/ReceiptItemModel';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  //  borderBottomColor: '#bff0fd',
  //  borderBottomWidth: 1,
    //    alignItems: 'center',
    marginTop: 40,
   // height: 24,
    display: 'flex',
    fontWeight: 'bold',
  },
  description: {
    width: '85%',
    textAlign: 'right',
 //   borderRightColor: borderColor,
 //   borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

type TableFooterProps = {
  items: ReceiptItemModel[];
};

const TableFooter = ({ items }: TableFooterProps) => {
  const total = items
    .map((item) => item.netAmt)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{total.toFixed(2)}</Text>
    </View>
  );
};

export default TableFooter;
