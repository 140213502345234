import React, { useEffect } from "react";
import { HandleLogout, SetIsLoggedIn, useHandleLogout } from "utils/Globals";
import { useNavigate } from "react-router";

const Logout = () => {
  const navigate = useNavigate();
  const [logout] = useHandleLogout();

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <> </>;
};

export default Logout;
