import {
  getLogSessionId,
  LogMessageRepo,
  LogSessionEndRepo,
  LogSessionStartRepo,
} from "repo/LoggingRepo";
import { GetCompanyId } from "utils/Globals";
import ServiceBase from "./ServiceBase";

export default class LoggingService extends ServiceBase {
  public sessionId: string;
  public logEventIndex: number = 0;
  public isInitialized: boolean = false;

  constructor() {
    super();
    this.init();
  }

  // on app initialize companyid is undefined unless client logs in,
  // need to wait for valid company id for doing logs
  init() {
    if (GetCompanyId() === null) return;
    this.sessionId = getLogSessionId();
    this.isInitialized = true;
    this.logSessionStart();
  }

  action() {}

  logSessionStart() {
    if (!this.isInitialized) this.init();

    if (this.isInitialized) LogSessionStartRepo(this.sessionId);
  }

  logSessionEnd() {
    if (!this.isInitialized) this.init();
    if (!this.isInitialized) return

    LogSessionEndRepo(this.sessionId);
  }

  log(tag, message) {
    if (!this.isInitialized) this.init();

    if (!this.isInitialized) return
    this.logEventIndex++;
    LogMessageRepo(this.sessionId, this.logEventIndex, tag, message);
  }
}
