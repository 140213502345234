import React from 'react';
import App from 'App';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Routes } from 'react-router';
import { GetIsLoggedIn } from 'utils/Globals';
import Dashboard from 'components/Dashboard';
import Items from 'components/item/Items';
import PrivateRoute from 'route/PrivateRoute';
import LoginPage from 'components/Login';
import { LayoutAuthenticated } from 'route/LayoutAuthenticated';
import LayoutAnonymous from 'route/LayoutAnonymous';
import AddEditItem from 'components/item/AddEditItem';
import Receipts from 'components/receipt/Receipts';
import AddEditReceipt from 'components/receipt/AddEditReceipt';
import Authenticate from 'components/Authenticate';
import Logout from 'components/Logout';
import Expenses from 'components/expense/Expenses';
import ProfitLoss from 'components/reports/pl/ProfitLoss';
import PaidRoute from 'route/PaidRoute';
import AddEditExpenses from 'components/expense/AddEditExpense'
import DeleteAccount from 'components/pages/deleteaccount';

// export default function AppRoutes() {
//   const privateRoutes = [
//     {
//       key: "home",
//       path: "/",
//       element: Dashboard,
//       exact: true,
//     },
//     {
//       key: "items",
//       path: "/items",
//       component: Items,
//       exact: true,
//     },
//     {
//       key: "additem",
//       path: "/additem",
//       component: AddEditItem,
//       exact: true,
//     },
//     {
//       key: "receipts",
//       path: "/receipts",
//       component: Receipts,
//       exact: true,
//     },
//     {
//       key: "addreceipt",
//       path: "/addreceipt",
//       component: AddEditReceipt,
//       exact: true,
//     },
//   ];
// }

const routes = [
  {
    path: '/app',
    element: <LayoutAuthenticated />,
    children: [
      { path: '', element: <Navigate to="/app/dashboard" /> }, // Make the path relative
      { path: 'dashboard', element: <Dashboard /> }, // Remove leading slash
      { path: 'items', element: <Items /> }, // Remove leading slash
      { path: 'additem', element: <AddEditItem /> }, // Remove leading slash
      { path: 'receipts', element: <Receipts /> }, // Remove leading slash
      { path: 'addreceipt', element: <AddEditReceipt /> }, // Remove leading slash
      { path: 'expenses', element: <Expenses /> }, // Remove leading slash
      {
        path: 'profitloss',
        element: <PaidRoute />,
        children: [{ path: '', element: <ProfitLoss /> }], // Make the path relative
      },
      { path: 'addexpense', element: <AddEditExpenses /> }, // Remove leading slash
      { path: 'logout', element: <Logout /> }, // Remove leading slash
      { path: '*', element: <Navigate to="/aaa" /> }, // Remove leading slash
    ],
  },
  {
    path: '/',
    element: <LayoutAnonymous />,
    children: [
      { path: 'login', element: <LoginPage /> },
      // { path: 'register', element: <RegisterView /> },
      // { path: '404', element: <NotFoundView /> },
      { path: '', element: <Navigate to="/app/dashboard" /> }, // Make the path relative
      { path: 'deleteaccount', element: <DeleteAccount /> }, // Make the path relative
    ],
  },
];
export default routes;
//   return (
//     <BrowserRouter>
//     {/* <Route path='/' element={<Dashboard />} /> */}
//       <Routes>
//         <Route path={"/login" | "/signup" | "/forgot-password"}>
//           <LayoutAnonymous>
//             <Route  path="/login" element={<LoginPage />} />
//           </LayoutAnonymous>
//         </Route>

//         <Route path="/">
//           <LayoutAuthenticated>
//             <Routes>
//               {privateRoutes.map((privateRouteProps) => (
//                 <PrivateRoute
//                   {...privateRouteProps}
//                   authenticated={GetIsLoggedIn()}
//                 />
//               ))}
//             </Routes>
//           </LayoutAuthenticated>
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// }
