import React from 'react';
import { Page, Document, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceTitle from './Title';
import Address from './Address';
import InvoiceNo from './InvoiceNo';
import ReceiptItemsTable from './ReceiptItemsTable';
import ThankyouMessage from './ThankyouMessage';
import logo from './Logo.png';
import { useAppSelector } from 'redux/hooks';
import { getCompanyData } from 'redux/selectors';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 15,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 2.5,
    flexDirection: 'column',
    display: 'flex',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const ReceiptPDF = ({ receipt, receiptItems, party }) => {
  const company = useAppSelector(getCompanyData);

  const ShowPDF = () => (
    <Document>
      <Page style={styles.page}>
        {/* <Image style={styles.logo} src={logo} /> */}
        <InvoiceTitle company={company} />
        <InvoiceNo receipt={receipt} />
        <Address party={party} />
        <ReceiptItemsTable receiptItems={receiptItems} />
        <ThankyouMessage />
      </Page>
    </Document>
  );

  return (
    <>
      <PDFDownloadLink
        document={<ShowPDF />}
        fileName="receipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '10px',
          color: '#4a4a4a',
          backgroundColor: '#f2f2f2',
          border: '1px solid #4a4a4a',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download Receipt PDF'
        }
      </PDFDownloadLink>

      <ShowPDF />
    </>
  );
};

export default ReceiptPDF;
