import ServiceBase from "services/ServiceBase";
import { ReceiptItemTable } from "utils/Constants";
import { AdjustQuantityRepo, LoadAllItems, SaveItemRepo } from "repo/ItemRepo";
import ItemModel from "model/ItemModel";
import StockHistoryModel from "model/StockModel";
import { SaveStockHistory } from "repo/StockRepo";

export class AllItemsLoader extends ServiceBase {
  action() {
    LoadAllItems(
      (items: ItemModel[]) => {
        this.setdataLoaded(items);
        this.sendCallback(items);
      },
      (error) => this.handleError(error)
    );
  }
}

export class SaveItemService extends ServiceBase {
  action(item: ItemModel) {
    SaveItemRepo(item, (result) => this.sendCallback(result));
  }
}

export class AdjustQuantityService extends ServiceBase {
  action(itemid: string, quantityToChnage: number) {
    AdjustQuantityRepo(itemid, quantityToChnage)
  }
}

export class AdjustStockService extends ServiceBase {
  action(itemid: string, stockhistory: StockHistoryModel) {
    SaveStockHistory(itemid, stockhistory, (result) => {
      this.showNotification(result)
    })
  }
}