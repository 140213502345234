// npm typescipt-json-serializer has bug and it was not converting anything and throwing exception
// @JsonProperty('pp') purchasePrice : number;
export default class ItemDto {
  ItemDto(){
    
  }
  
  public id: string;
  public cid: string; //categoryid
  public bd: string; // brandid
  
  public ds: string; //desc
  public ia: number; //isactive
  public pid: string; //parentid
  public hsi: string; //has sub items
  public d: number; //date
  
  public sku: string;
  public n: string;
  public sp: number;
  public pp: number;
  public st?: StockDto;
}

export class StockDto {
 // public Quantity: number; //exclude
  public loc: string;
  public cr: number; //Carton
  public ct: number; //itemcount
  public ipc: number; //itemspercarton
  public ba: string; //batch
  public mfgd: number;
  public expd: number;
}