import ExpenseDto from "dto/ExpenseDto";
import firebase from "firebase";
import ExpenseModel, { ExpenseDtoToModel, ExpenseModelToDto } from "model/ExpenseModel";
import { callbackify } from "node:util";
import { ExpenseTable } from "utils/Constants";

export function LoadAllExpenses(callback?, error?) {

  const expenseRef = firebase.database().ref(ExpenseTable());
  expenseRef.once(
    "value",
    (snapshot) => {
      const expenses: ExpenseModel[] = [];

      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        const childData: ExpenseDto = childSnapshot.val();
        childData.id = key;
        expenses.push(ExpenseDtoToModel(childData));
      });

      if (callback) callback(expenses);
    },
    (err) => {
      if (error) error(err);
    }
  );
}

export function LoadExpenseOfMonth(startDate, endDate, callback?, error?) {
  const expenses: ExpenseModel[] = [];
  let totalExpense = 0;

  const expenseRef = firebase.database().ref(ExpenseTable());
  expenseRef.orderByChild('_d').startAt(startDate).endAt(endDate).once(
    "value",
    (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        const childData: ExpenseDto = childSnapshot.val();
        childData.id = key;
        const expModel = ExpenseDtoToModel(childData)
        totalExpense += expModel.Amount
        expenses.push(expModel);
      });

      if (callback) callback(totalExpense, expenses);
    },
    (err) => {
      if (error) error(err);
    }
  );
}

export async function SaveExpenseRepo(expense: ExpenseModel, callback?) {
  const expenseDto = JSON.parse(JSON.stringify(ExpenseModelToDto(expense)))

  const expenseRef = firebase.database().ref(ExpenseTable());
  
  try {
    if (expense.Id === undefined) {
      expense.Id = expenseRef.push().key;

      // error handling in firebase function
      //https://firebase.google.com/docs/functions/terminate-functions
      //https://stackoverflow.com/questions/47652149/best-way-to-capture-error-from-database-firebase-javascript
      await expenseRef.child(expense.Id).set(expenseDto, (err) => {
        if (err && callback) callback(err); // if no permission
      });
    } else {
      await expenseRef.child(expense.Id).update(expenseDto, (err) => {
        if (err && callback) callback(err);
      });
    }

    if (callback) callback("success");
  } catch (e) {
    console.log(e);
    if (callback) callback(e);
  }

}
