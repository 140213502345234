import React, { useState, useEffect } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { Redirect, useNavigate } from "react-router";
import { Layout, Menu } from "antd";
import UserSideBar from "./sidebar/UserSideBar";
import firebase from "utils/Firebase";
import { Button, TextField } from "antd";
import { HandleLogout, SetIsLoggedIn } from "utils/Globals";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ReceiptPaymentTable } from "utils/Constants";
import {
  LastXDaysReceiptPaymentLoader,
  LoadRecentReceiptsService,
} from "services/DashboardService";
import useFetcher from "utils/useFetcher";
import SummaryReceiptsList from "components/receipt/SummaryReceiptsList";
import { useAppSelector } from "redux/hooks";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default function Dashboard() {
  // const [loggedOut, setloggedOut] = useState();
  const navigate = useNavigate();
  const companyState = useAppSelector((state) => state.root.company);

  const [loadProfitLossChartData, dataLoaded, setdataLoaded] = useFetcher(
    LastXDaysReceiptPaymentLoader
  );
  const [loadRecentReceipts, receipts, setReceipts] = useFetcher(
    LoadRecentReceiptsService
  );
  const [plData, setPLData] = useState();

  console.log("company state = ", companyState);

  useEffect(() => {
    loadProfitLossChartData((result) => {
      const bins = [];
      let dateIndex = -1,
        cDate = "";
      // combine same date sales into one bin
      for (let iter = 0; iter < result.length; iter++) {
        if (cDate !== result[iter].DateStr) {
          cDate = result[iter].DateStr;
          dateIndex++;
          bins[dateIndex] = { Amount: 0, DateStr: result[iter].DateStr };
        }
        bins[dateIndex].Amount += result[iter].Amount;
      }
      setPLData([...bins]); // chart expects immutable data
    });

    loadRecentReceipts((result) => {
      console.log("receipts", result);
    });
  }, []);

  const handleLogout = (e) => {
    HandleLogout(navigate);
  };

  // if (loggedOut) return <Redirect to="/" />;

  function BarChart1() {
    if (plData === undefined) return null;

    return (
      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          data={plData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="DateStr" height={30} interval={0} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Amount" name="Daily Sales" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        {companyState.edition > 10 && (
          <Grid item lg={6} sm={12} xs={12}>
            <h2>Last 7 days sales</h2>
            <Grid>
              <BarChart1 />
            </Grid>
            <Grid>
              {/* <div style={{ background: "blue" }}>hello</div> */}
            </Grid>
          </Grid>
        )}
        <Grid item lg={companyState.edition == 10 ? 12 : 6} sm={12} xs={12}>
          <h2>Recent Sales</h2>
          <Grid>
            <SummaryReceiptsList
              receipts={receipts}
              history={navigate}
              loading={receipts ? false : true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
