import ItemSaleDto from "dto/ItemSaleDto";

export default class ItemSaleModel {
  public receiptId: string;
  public date: number;
  public receiptItemId: string;
  public itemId: string;
  public quantity: number;
  public amount: number;
}

export function dtoToItemSaleModel(dto: ItemSaleDto) {
  let is: ItemSaleModel = new ItemSaleModel();
  is.quantity = dto.q;
  is.receiptId = dto.r;
  is.amount = dto.a;
  is.date = dto.d;
  is.itemId = dto.i;
  is.receiptItemId = dto.t;

  return is;
}

export function itemSaleModelToDto(model: ItemSaleModel) {
  let is: ItemSaleDto = new ItemSaleDto();
  is.q = model.quantity;
  is.r = model.receiptId;
  is.a = model.amount;
  is.d = model.date;
  is.i = model.itemId;
  is.t = model.receiptItemId;

  return is;
}
