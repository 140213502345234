import { RootState } from 'redux/store';

export function getAllReceipts(state: RootState) {
  return state !== null && state.root.receiptsList;
}

export function getAllExpenses(state: RootState) {
  return state !== null && state.root.expenseList;
}

export function isExpenseLoaded(state: RootState) {
  return state !== null && state.root.isExpenseLoaded;
}

export function isLoggedIn(state: RootState) {
  return state !== null && state.root.isLoggedIn;
}

export function getPlData(state: RootState, stDate: number) {
  return state !== null && state.root.plData[stDate];
}
export function getCompanyData(state: RootState) {
  return state !== null && state.root.company;
}

export function getExpenseMonthly(state: RootState, stDate: number) {
  return state !== null && state.root.expensesByMonthly[stDate];
}
