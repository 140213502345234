import { ReceiptItemModel } from "model/ReceiptItemModel";
import { ReceiptModel } from "model/ReceiptModel";
import { saveItemSaleReportRepo } from "repo/ReportRepo";
import ServiceBase from "services/ServiceBase";

export class ReportService extends ServiceBase {

  saveItemMonthlyReport(receiptData: ReceiptModel, receiptItems:  ReceiptItemModel[]) {
    saveItemSaleReportRepo(receiptData, receiptItems)
  }
}
