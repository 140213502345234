import LoadProfitLossRepo from "repo/ProfitLossRepo";
import ServiceBase from "services/ServiceBase";

export default class ProfitLossService extends ServiceBase {
  action(stDate, endDate) {
    LoadProfitLossRepo(
      stDate,
      endDate,
      (plData) => this.callback(plData),
      (err) => this.handleError(err)
    );
  }
}
