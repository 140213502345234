import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: 10,
  },
  padded: {
    padded: 10,
  },
  heading: {
    margin: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  zindex: {
    zindex: 10000000,
  },
}),  {index: 1});

export default useStyles;
