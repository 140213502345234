import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  Modal,
  Button,
  Checkbox,
  AutoComplete,
  Tooltip,
} from "antd";
import { useAppSelector } from "redux/hooks";

export default function CheckoutModal({
  visible,
  onCancel,
  handleSubmit,
  confirmLoading,
  returnAmount,
  bill,
  finalNetAmount,
  overAllDiscount,
}) {

  const [customerPaid, setCustomerPaid] = useState(0);
  const [isCredit, setIsCredit] = useState(false);
  const companyState = useAppSelector((state) => state.root.company);

  useEffect(() => {
    setCustomerPaid(finalNetAmount)
  }, [finalNetAmount])

  const isPremiumVersion = () => 
    companyState.edition === 30 ? true : false 
  
  return (
    <Modal
      visible={visible}
      title="Checkout"
      okText="Checkout"
      onCancel={onCancel}
      onOk={() => handleSubmit(isCredit, Number(customerPaid))}
      confirmLoading={confirmLoading}
    >
      <Form>
        {returnAmount > 0 && (
          <Form.Item label={"Return"}>{returnAmount}</Form.Item>
        )}
        <Form.Item label={"Bill"}>{bill}</Form.Item>

        <Form.Item label={"Discount"}>{overAllDiscount}</Form.Item>
        <Form.Item label={"Net"}>
          {overAllDiscount > 0 ? finalNetAmount : bill}
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label={"Customer Paid"}>
          <Input
            value={customerPaid}
            onChange={(value) => setCustomerPaid(Number(value.target.value))}
          />
        </Form.Item>
        {isPremiumVersion() && 
          <Checkbox value={isCredit} onChange={()=> setIsCredit(!isCredit)}>
            Its a credit sale
          </Checkbox>
        }
        {isCredit && (
          <p>
            You may leave the 'Customer Paid' field blank OR put any partial
            payment done by customer
          </p>
        )}
      </Form>
    </Modal>
  );
}

function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

// class NumericInput extends React.Component {
//   onChange = (e) => {
//     const { value } = e.target;
//     const reg = /^-?\d*(\.\d*)?$/;
//     if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
//       this.props.onChange(value);
//     }
//   };

//   // '.' at the end or only '-' in the input box.
//   onBlur = () => {
//     const { value, onBlur, onChange } = this.props;
//     let valueTemp = value;
//     if (value.charAt(value.length - 1) === "." || value === "-") {
//       valueTemp = value.slice(0, -1);
//     }
//     onChange(valueTemp.replace(/0*(\d+)/, "$1"));
//     if (onBlur) {
//       onBlur();
//     }
//   };

//   render() {
//     const { value } = this.props;
//     const title = value ? (
//       <span className="numeric-input-title">
//         {value !== "-" ? formatNumber(value) : "-"}
//       </span>
//     ) : (
//       "Input a number"
//     );
//     return (
//       <Tooltip
//         trigger={["focus"]}
//         title={title}
//         placement="topLeft"
//         overlayClassName="numeric-input"
//       >
//         <Input
//           {...this.props}
//           onChange={this.onChange}
//           onBlur={this.onBlur}
//           placeholder="Customer Paid"
//           maxLength={25}
//         />
//       </Tooltip>
//     );
//   }
// }

