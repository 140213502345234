import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { ReceiptTable } from "utils/Constants";
import moment from "moment";
import { dateFormat, dateStringFormat, firebaseRefs } from "utils/Globals";
import { ReceiptDto, ReceiptStatus } from "dto/ReceiptDto";
import { ReceiptModel } from "model/ReceiptModel";
import { callbackify } from "util";

export function receiptDtoToModel(r: ReceiptDto) {
  let recpt: ReceiptModel = new ReceiptModel();
  recpt.Id = r.id;
  recpt.RefNum = r.refNum;
  recpt.PartyId = r.pid;
  recpt.Date = r.d;
  recpt.DateNum = r._d;
  recpt.BillDiscount = r.bd;
  recpt.Sale = r.sl;
  recpt.Ret = r.ret;
  recpt.Net = r.net;
  recpt.isSale = r.is;
  recpt.PaidAmount = r.pa;
  recpt.TotalPurchPrice = r.tpp;
  recpt.Status = r.st;
  recpt.IsDiscPercent = r.ip;
  recpt.Remarks = r.rem;
  recpt.ItemRecvd = r.ir;

  return recpt;
}

export function receiptModelToDto(r: ReceiptModel) {
  const recpt: ReceiptDto = new ReceiptDto();

  recpt.id = r.Id;

  recpt.pid = r.PartyId;
  recpt.d = r.Date;
  recpt._d = r.DateNum;
  recpt.bd = r.BillDiscount;
  recpt.sl = r.Sale;
  recpt.ret = r.Ret;
  recpt.net = r.Net;
  recpt.is = r.isSale;
  recpt.pa = r.PaidAmount;
  recpt.tpp = r.TotalPurchPrice;
  recpt.st = r.Status;
  recpt.ip = r.IsDiscPercent;
  recpt.rem = r.Remarks;
  recpt.ir = r.ItemRecvd;

  return recpt;
}

export async function SaveReceipt(r: ReceiptModel, callback?) {
  let receipt = receiptModelToDto(r);
  // to remove undefined fields which give error in firebase DB save
  let js = JSON.parse(JSON.stringify(receipt));
  let recptRef = firebase.database().ref(ReceiptTable());

  if (r.Id === undefined) {
    r.Id = recptRef.push().key;
    await recptRef.child(r.Id).set(js, (err) => {
      if (err && callback) callback(err);
    });
  } else {
    await recptRef.child(r.Id).update(js, (err) => {
      if (err && callback) callback(err);
    });

    if (callback) callback("success");
  }

  //recptRefUpdate.update(js);
}

export function UpdateReceiptTotalPurchPrice(r: ReceiptModel) {
  let recptRef = firebase.database().ref(ReceiptTable() + "/" + r.Id);

  recptRef.child("tpp").set(r.TotalPurchPrice);
}

export let ReceiptsList: ReceiptModel[] = [];

export function LoadReceipts(callback?, error?) {
  const receipts = firebase.database().ref(ReceiptTable());
  receipts.on(
    "value",
    (snapshot) => {
      let recpts = []; // clear ... ItemList cannot be reassigned as its imported from outside file
      //console.log("Items = ", snapshot.val());
      snapshot.forEach((childSnapshot) => {
        var key = childSnapshot.key;
        let childData: ReceiptDto = childSnapshot.val();
        childData.id = key;
        recpts.push(receiptDtoToModel(childData));
      });

      if (callback) callback(recpts);
      //ItemList = snapshot.val()
    },
    (err) => {
      if (error) error(err);
    }
  );

  // push in db refs so all are off() in signout
  firebaseRefs.push(receipts)
}

export function LoadRecentReceipts(count = 10, callback?, error?) {
  var receipts = firebase.database().ref(ReceiptTable());
  receipts.limitToLast(count).once(
    "value",
    (snapshot) => {
      let recpts = []; // clear ... ItemList cannot be reassigned as its imported from outside file
      //console.log("Items = ", snapshot.val());
      snapshot.forEach((childSnapshot) => {
        var key = childSnapshot.key;
        let childData: ReceiptDto = childSnapshot.val();
        childData.id = key;
        recpts.push(receiptDtoToModel(childData));
      });

      if (callback) callback(recpts);
      //ItemList = snapshot.val()
    },
    (err) => {
      if (error) error(err);
    }
  );
}

export function UpdateReceiptPaidAmountRepo(
  receipt: ReceiptModel,
  callback?,
  error?
) {
  let query: any = {
    pa: receipt.PaidAmount,
  };

  // all dues done, in case installments
  if (receipt.Status != ReceiptStatus.Done && receipt.PaidAmount >= receipt.Net)
    query.st = ReceiptStatus.Done;

  firebase
    .database()
    .ref(ReceiptTable())
    .child(receipt.Id)
    .update(query, (err) => {
      if (err) {
        if (error) error(err);
      }
    });
}
