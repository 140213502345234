import React, { useState, useEffect } from "react";
import Login from "components/Login";
import firebase from "utils/Firebase";
import { SetIsLoggedIn } from 'utils/Globals'
import Dashboard from "components/Dashboard";

function Authenticate() {
  const [loggedIn, setloggedIn] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setloggedIn(true);
        SetIsLoggedIn(true)
      } else {
        setloggedIn(false);
        SetIsLoggedIn(false)
      }
    });

  
    return () => { };
  }, []);

  let component;
  if (loggedIn === false) component = <Login />;
  else if (loggedIn === true) component = <Dashboard />;
  else component = <div align='center'>'Loading...'</div>

  return <div className="App">{component}</div>;
}

export default Authenticate;
