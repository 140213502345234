import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from 'components/style';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
//import Logo from 'src/components/Logo';
import { themeColor } from 'utils/Globals'

const colortheme = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' },
    //  secondary: { main: "#555", contrastText: "#f00" }
  },
});

const style = makeStyles((theme) => ({
  header1: {
    color: '#e74c3c',
  },
}));

const TopBar = ({ className = '', onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const t = style();

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          {/* <Logo /> */}
          <img src="/Logo.png" height={64} alt="Logo" />
          <Hidden xsDown>
            <Typography
              style={{
                display: 'inline-block',
                color: 'white',
                fontWeight: 600,
              }}
              className={classes.margin}
              variant="h5"
              color="primary"
            >
              Igloo POS
            </Typography>
          </Hidden>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              {/* <NotificationsIcon /> */}
            </Badge>
          </IconButton>
          <IconButton color="inherit">{/* <InputIcon /> */}</IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
