import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import ItemsListView from 'components/item/ItemsListView';
import dayjs from 'dayjs';
import CompanyModel from 'model/CompanyModel';
import ExpenseModel from 'model/ExpenseModel';
import ItemModel from 'model/ItemModel';
import PartyModel from 'model/PartyModel';
import { ReceiptModel } from 'model/ReceiptModel';
import { ExpenseValueType, PlDataType } from 'utils/types';

type rootState = {
  value: number;
  company: CompanyModel;
  receiptsList: ReceiptModel[];
  itemsList: ItemModel[];
  expenseList: ExpenseModel[];
  isExpenseLoaded: boolean;
  isLoggedIn: boolean;
  expensesByMonthly: { month: number; value: ExpenseValueType };
  plData: { value: PlDataType };
  parties: PartyModel[];
};

export const initialState = {
  value: 0,
  company: {},
  receiptsList: [],
  itemsList: [],
  expenseList: [],
  isExpenseLoaded: false,
  isLoggedIn: false,
  plData: {},
  expensesByMonthly: {},
  parties: [],
} as rootState;

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    companyData: (state, data: PayloadAction<CompanyModel>) => {
      state.company = data.payload;
    },
    setReceiptsList: (state, data: PayloadAction<ReceiptModel[]>) => {
      state.receiptsList = data.payload;
    },
    setItemsList: (state, data: PayloadAction<ItemModel[]>) => {
      state.itemsList = data.payload;
    },
    setExpenseList: (state, data: PayloadAction<ExpenseModel[]>) => {
      state.expenseList = data.payload;
      state.isExpenseLoaded = true;
    },
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state = initialState;
    },
    setLoginState: (state, data: PayloadAction<boolean>) => {
      state.isLoggedIn = data.payload;
    },
    saveProfitLossData: (state, data: PayloadAction<PlDataType>) => {
      // set profitloss data based on key, ie month as number
      state.plData[data.payload.key] = data.payload;
    },
    saveExpenseData: (state, data: PayloadAction<ExpenseValueType>) => {
      // set profitloss data based on key, ie month as number
      state.expensesByMonthly[data.payload.key] = data.payload;
    },
    // update expense use case is handled manually here, although it could be done thru .on() firebase method
    // which would automatically update redux state vars, but done like this to preserve bandwidth
    updateExpense: (state, data: PayloadAction<ExpenseModel>) => {
      const dateKey = dayjs(data.payload.Date).startOf('month').valueOf();
      // new month of Expense is not in memory yet
      if (state.expensesByMonthly[dateKey] === undefined) {
        // remove from any other location
        removeFromExpense(state, data.payload.Id)
        state.expensesByMonthly[dateKey] = []
        state.expensesByMonthly[dateKey].push(data.payload);
        return;
      }

      const index = state.expensesByMonthly[dateKey].expenseList.findIndex(
        (e) => e.Id === data.payload.Id
      );
      // if found in same month then means month is not changed
      if (index >= 0)
        state.expensesByMonthly[dateKey].expenseList.splice(index, 1, data.payload);
      else {
        // remove from previous month 
        removeFromExpense(state, data.payload.Id)

        // insert in new month
        state.expensesByMonthly[dateKey].expenseList.push(data.payload);
      }
    },
    setParties: (state, data: PayloadAction<PartyModel[]>) => {
      state.parties = data.payload;
    },
  },
});

const removeFromExpense = (state, id) => {
  let index = 0
  for (const [key, value] of Object.entries(state.expensesByMonthly)) {
    index = state.expensesByMonthly[key].expenseList.findIndex(
      (e) => e.Id === id
    );

    if (index >= 0) {
      state.expensesByMonthly[key].expenseList.splice(index, 1);
      break;
    }
  }
}

// Action creators are generated for each case reducer function
export const {
  companyData,
  setReceiptsList,
  setItemsList,
  logout,
  setExpenseList,
  setLoginState,
  saveProfitLossData,
  saveExpenseData,
  updateExpense,
  setParties,
} = rootSlice.actions;

export default rootSlice.reducer;
