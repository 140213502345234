import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { ReceiptNotificationTable } from "utils/Constants";
import moment from "moment";
import { ReceiptNotificationDto } from "dto/ReceiptNotificationDto";
import { ReceiptNotificationModel } from "model/ReceiptNotificationModel";

// export class ReceiptNotificationDto {
//   private pid: string;
//   get PartyId(): string {
//     return this.pid;
//   }
//   set PartyId(pid: string) {
//     this.pid = pid;
//   }

//   private lp: number; //date
//   public get LastPaidDate(): number {
//     return this.lp;
//   }
//   public set LastPaidDate(v: number) {
//     this.lp = v;
//   }

//   private d: number; //date
//   public get ReceiptDate(): number {
//     return this.d;
//   }
//   public set ReceiptDate(v: number) {
//     this.d = v;
//   }

//   private du: number; //date
//   public get DueAmount(): number {
//     return this.du;
//   }
//   public set DueAmount(v: number) {
//     this.du = v;
//   }

//   private o: boolean;
//   public get isDone(): boolean {
//     return this.o;
//   }
//   public set isDone(v: boolean) {
//     this.o = v;
//   }

//   private p: boolean;
//   public get isPurch(): boolean {
//     return this.p;
//   }
//   public set isPurch(v: boolean) {
//     this.p = v;
//   }
// }

function FirebaseToPOJO(r) {
  let recptntfction: ReceiptNotificationModel = new ReceiptNotificationModel();
  recptntfction.PartyId = r.pid;
  recptntfction.LastPaidDate = r.lp;
  recptntfction.ReceiptDate = r.d;
  recptntfction.DueAmount = r.du;
  recptntfction.isDone = r.o;
  recptntfction.isPurch = r.p;

  return recptntfction;
}

function ReceiptNotificationPojoToFirebase(r: ReceiptNotificationModel) {
  let recptntfction: ReceiptNotificationDto = new ReceiptNotificationDto();
  recptntfction.pid = r.PartyId;
  recptntfction.lp = r.LastPaidDate;
  recptntfction.d = r.ReceiptDate;
  recptntfction.du = r.DueAmount;
  recptntfction.o = r.isDone;
  recptntfction.p = r.isPurch;

  return recptntfction;
}

export let ReceiptNotificationList: ReceiptNotificationModel[] = [];

export const CreateReceiptNotification = () => {
  let recptNotfctn = new ReceiptNotificationModel();
  recptNotfctn.ReceiptDate = moment(moment().format()).unix() * 1000;
 // recptNotfctn.DueAmount = receiptData.Net - receiptData.PaidAmount;
  recptNotfctn.LastPaidDate = moment(moment().format()).unix() * 1000;
  recptNotfctn.isPurch = false;
  recptNotfctn.isDone = false;
 // recptNotfctn.PartyId = partyId;
  return recptNotfctn;
};

// create new receipt notification
export function SaveReceiptNotification(rid: string, rn: ReceiptNotificationModel) {
  let recptItemRef = firebase.database().ref(ReceiptNotificationTable());
  let raw = ReceiptNotificationPojoToFirebase(rn);
  let js = JSON.parse(JSON.stringify(raw));

  //let id = recptItemRef.push().key;
  recptItemRef.child(rid).set(js);
}

export function UpdateReceiptNotification(rid, payment, error?: any) {
  let ref = firebase.database().ref(ReceiptNotificationTable()).child(rid);

  ref.once("value", (snapshot) => {
    const data = snapshot.val();
    data.du = data.du - payment;
    if (data.du <= 0) data.o = true; // Status is Done
    ref.update(data, (err) => {
      if (err) {
        if (error) error(err);
      }
    });
  });
}

export class AllReceiptNotificationLoader extends ServiceBase {
  action() {
    var ReceiptNotification = firebase.database().ref(ReceiptNotificationTable());
    ReceiptNotification.once("value", (snapshot) => {
      ReceiptNotificationList.length = 0; // clear ... ItemList cannot be reassigned as its imported from outside file
      snapshot.forEach((childSnapshot) => {
        let childData: ReceiptNotificationModel = childSnapshot.val();
        ReceiptNotificationList.push(FirebaseToPOJO(childData));
      });
      this.setdataLoaded(true);
    });
  }
}
