export class ReceiptItemDto {
  public t: string;
  public n: string;
  public rid: string;
  public pr: number;
  public id: string;
  public iid: string;
  public pp: number;
  public am: number;
  public di: number;
  public sku: string;
  public dp: boolean;
  public q: number;
}
