import React, { useState } from "react";
import dayjs from "dayjs";
import { dateFormat, dateStringFormat } from "utils/Globals";

const Columns = (page?) => {
  
  const columns: any = [
    {
      title: "Index",
      key: "index",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
      width: "5%",
    },
    {
      title: "Ref #",
      dataIndex: "RefNum",
      key: "RefNum",
      sorter: {
        compare: (a, b) => a.RefNum - b.RefNum,
      },
    },
    // {
    //   title: "DateNum",
    //   dataIndex: "DateNum",
    //   key: "daten",
    //   // sorter: {
    //   //   compare: (a, b) => a.RefNum - b.RefNum,
    //   // },
    // },
    {
      title: "Date",
      //  dataIndex: "Date",
      key: "date",
      defaultSortOrder: "descend",
      // sorter: {
      //   compare: (a, b) => a.DateNum - b.DateNum,
      // },
      render: (text, record, index) => {
        if (!record.DateNum) return "";
        return dayjs(record.DateNum).format(dateStringFormat);
      },
      sorter: {
        compare: (a, b) => {
          if (a.DateNum && b.DateNum) return a.DateNum - b.DateNum;
          if (!a.DateNum && !b.DateNum) return 0;
          if (!a.DateNum) return -1;
          if (!b.DateNum) return 1;
        },
      },
    },
    {
      title: "Bill Amount",
      dataIndex: "Sale",
      key: "sale",
      sorter: {
        compare: (a, b) => a.Sale - b.Sale,
      },
    },
    // {
    //   title: "Sale/Purchase",
    //   dataIndex: "isSale",
    //   key: "issale",
    // },
    {
      title: "Paid Amount",
      dataIndex: "PaidAmount",
      key: "pamount",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      align: "center",
      render: (text, record, index) => ({
        props: {
          style: { textAlign: "center" },
        },
        children:
          text === 1 ? (
            <div className="green">On Hold</div>
          ) : text === 5 ? (
            <div className="red">Credit</div>
          ) : (
            "Paid"
          ),
      }),
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   width: 200,
    //   render: (text, record, index) => (
    //     <span>
    //       <Button
    //         type="primary"
    //         shape="circle"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           navigate('/app/addreceipt', { state: record });
    //         }}
    //       />
    //     </span>
    //   ),
    // },
  ];
  return columns;
};

export default Columns
