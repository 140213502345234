import { useNavigate } from 'react-router';
import { useAppDispatch } from 'redux/hooks';
import { logout } from 'redux/reducers/rootReducer';
import firebase from 'utils/Firebase';

export let isLoggedIn = false;

export function SetIsLoggedIn(val) {
  isLoggedIn = val;
  localStorage.setItem('isLoggedIn', val);
}

export function GetIsLoggedIn() {
  return localStorage.getItem('isLoggedIn') === 'true'; // localstorage only stores strings.. no booleans
}

export function SetCompanyId(val) {
  localStorage.setItem('companyId', val);
}

export function GetCompanyId() {
  return localStorage.getItem('companyId');
}

export function useHandleLogout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function doLogout() {
    dispatch(logout());
    HandleLogout(navigate);
  }

  return [doLogout];
}

export async function HandleLogout(navigate) {
  try {
    firebaseRefs.forEach((ref) => ref.off());

    await firebase.auth().signOut();
    SetIsLoggedIn(false);
    navigate('/login');
  } catch (err) {
    console.log(err);
  }
}

export const firebaseRefs: firebase.database.Reference[] = [];
export const dateFormat = 'YYYY-MM-DD';
export const dateStringFormat = 'ddd, DD MMM YYYY, hh:mm a';
export const dateStringFormatShort = 'ddd, DD MMM YY';

export const dateFormatMMM_YY = 'MMM-YYYY';

export const themeColor = '#2980b9';

export class LogMessageType {
  m?: string | Map<number, string> | null; // message
}

export class LogEvent extends LogMessageType {
  d: number;
  t: number;

  // tag: string;
  // message: string | Map<number, string>
}

export enum Edition {
  Free = 10,
  Pro = 20,
  Premium = 30,
}

export enum ExpenseType {
  Bill = 1,
  Salary = 2,
  Food = 3,
  Other = 4,
}

export namespace ExpenseType {
  export function toString(dir: ExpenseType): string {
    return ExpenseType[dir];
  }

  export function fromString(dir: string): ExpenseType {
    return (ExpenseType as any)[dir];
  }
}
