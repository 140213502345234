import { notification } from 'antd';

export enum Result {
  SUCCESS,
  FAILURE,
}
export default abstract class ServiceBase {
  history: any;
  callback: any;
  setdataLoaded: any;
  dispatch: any;

  constructor(hist?, callback?, setdataLoaded?, dispatch?) {
    // if (arguments.length === 4) {
    this.history = hist;
    this.callback = callback;
    this.setdataLoaded = setdataLoaded;
    this.dispatch = dispatch;
    //}
  }

  action(...params: any[]) {}

  cleanup() {}

  sendCallback(params) {
    if (this.callback) this.callback(params);
  }

  showNotification(result) {
    if (result === Result.SUCCESS) this.notifySuccess('Saved');
    else this.handleError(result);
  }

  notifySuccess(notice) {
    notification.success({
      message: notice,
      //  description: error.message ? error.message.toString() : "",
      placement: 'bottomRight',
    });
  }

  handleError(error) {
    console.log(error);
    notification.error({
      message: error.code ? error.code.toString() : error.toString(),
      description: error.message ? error.message.toString() : '',
      placement: 'bottomRight',
    });

    if (error.code)
      if (error.code.includes('PERMISSION_DENIED')) this.history('/login');
  }
}
