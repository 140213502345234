import React, { useState } from 'react';
import { Form, Input, InputNumber, Col, Button, Row, Card } from 'antd';
import { Box, makeStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import useStyles from 'components/style';
import StockCard from './StockCard';
import { panelLayout, formItemLayout, buttonItemLayout } from 'utils/Styles';
import { getMfgDate, getExpDate } from 'components/item/AddEditItem';
import { useSender } from 'utils/useFetcher';
import { AdjustStockService } from 'services/ItemService';
import StockHistoryModel from 'model/StockModel';
import ItemModel from 'model/ItemModel';

export type AddStockProps = {
  onClose: () => any;
  item: ItemModel;
  open: boolean;
};

function AddStock(props: AddStockProps) {
  const classes = useStyles();
  const { onClose, item, open } = props;
  const [newQuantity, setNewQuantity] = useState(0);
  const [adjustStockservice] = useSender(AdjustStockService);

  const handleClose = () => {
    onClose();
  };

  const onQuantityChanged = (value) => {
    setNewQuantity(value);
  };

  const handleFinish = (values) => {
    console.log(values);

    const stock = new StockHistoryModel();

    if (values.mdate[0]) {
      stock.MfgDate = values.mdate[0].unix();
      stock.ExpDate = values.mdate[1].unix();
    }

    if (values.quantity) stock.ItemCount = values.quantity;
    if (values.batch) stock.Batch = values.batch;
    if (values.location) stock.Location = values.location;

    adjustStockservice(null, item.Id, stock);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title"></DialogTitle>
      <Row justify="center">
        <Col>
          <Form
            onFinish={handleFinish}
            {...formItemLayout}
            initialValues={{
              quantity: item.Stock?.ItemCount,
              batch: item.Stock?.Batch,
              mdate: [getMfgDate(item), getExpDate(item)],
              location: item.Stock?.Location,
            }}
          >
            <StockCard item={item} onQuantityChanged={onQuantityChanged} />

            <Typography className={classes.margin}>
              Existing stock is <b>{item.Stock?.ItemCount}</b>
            </Typography>
            <Typography className={classes.margin}>
              New stock will be <b>{newQuantity + item.Stock?.ItemCount}</b>
            </Typography>

            <Box display="flex" justifyContent="flex-end" p={2}>
              <Form.Item>
                <Button
                  className={classes.margin}
                  type="primary"
                  htmlType="submit"
                >
                  SAVE
                </Button>
              </Form.Item>
            </Box>
          </Form>
        </Col>
      </Row>
    </Dialog>
  );
}

export default AddStock;
