import ExpenseModel from 'model/ExpenseModel';
import {
  LoadAllExpenses,
  LoadExpenseOfMonth,
  SaveExpenseRepo,
} from 'repo/ExpenseRepo';
import ServiceBase from 'services/ServiceBase';
import { saveExpenseData, setExpenseList } from 'redux/reducers/rootReducer';
import { ExpenseValueType } from 'utils/types';

export class AllExpensesLoader extends ServiceBase {
  action() {
    LoadAllExpenses(
      (expenses: ExpenseModel[]) => {
        // traditional old way
        // this.setdataLoaded(expenses);
        // this.sendCallback(expenses);
        this.dispatch(setExpenseList(expenses)); // set thru redux
      },
      (error) => this.handleError(error)
    );
  }
}

export class LoadExpenseOfMonthService extends ServiceBase {
  action(stDate, endDate) {
    LoadExpenseOfMonth(
      stDate,
      endDate,
      (total, expList) => {
        const data: ExpenseValueType = {
          key: stDate,
          expenses: total,
          expenseList: expList,
          isLoaded: true,
        };
        this.dispatch(saveExpenseData(data));
      },
      (error) => this.handleError(error)
    );
  }
}

export class SaveExpenseService extends ServiceBase {
  action(expenseModel) {
    SaveExpenseRepo(expenseModel, (result) => {
      if (result === 'success') this.notifySuccess('Expense Saved!');
      else this.handleError(result);
      this.callback(result)
    });
  }
}
