import firebase from "firebase/app";
import "firebase/firestore";
const settings = { timestampsInSnapshots: true };

// const config = {
//     apiKey: "AIzaSyB_TcOOGo_3T4KYKOUpL3oU686sTNuyhqM",
//     authDomain: "gimonii-admin-panel.firebaseapp.com",
//     databaseURL: "https://gimonii-admin-panel.firebaseio.com",
//     projectId: "gimonii-admin-panel",
//     storageBucket: "gimonii-admin-panel.appspot.com",
//     messagingSenderId: "1035343764956"
// };

const variant = "PROD";

let config;
let defaultconfig
let logconfig;
let reportsconfig

if (variant === "DEV") {
  config = {
    apiKey: "AIzaSyAUZ5ODWYce7B7b-zOhTsDMIEU3az6NhXs",
    authDomain: "dev-sello.firebaseapp.com",
    projectId: "dev-sello",
    storageBucket: "dev-sello.appspot.com",
    messagingSenderId: "154265774897",
    appId: "1:154265774897:web:304fe620711908be",
  };

  defaultconfig = {
    databaseURL: "https://dev-sello.firebaseio.com",
    ...config,
  };

  // logs db
  logconfig = {
    databaseURL: "https://dev-sello-logs.firebaseio.com/",
    ...config,
  };

  // reports db
  reportsconfig = {
    databaseURL: "https://dev-sello-reports.firebaseio.com/",
    ...config,
  };
} 
else {
  config = {
    apiKey: "AIzaSyD_kGNyu2uEo1AaecA7QTPkrXUDXzb9V4Y",
    authDomain: "sello-c9f10.firebaseapp.com",
    projectId: "sello-c9f10",
    storageBucket: "sello-c9f10.appspot.com",
    messagingSenderId: "639008881222",
    appId: "1:639008881222:web:abc45cd34e1e928e09a847",
    measurementId: "G-Z5RW1SWGQ5"
  };

  defaultconfig = {
    databaseURL: "https://sello-c9f10.firebaseio.com",
    ...config,
  };

  // logs db
  logconfig = {
    databaseURL: "https://sello-logs.firebaseio.com/",
    ...config,
  };

  // reports db
  reportsconfig = {
    databaseURL: "https://sello-reports.firebaseio.com/",
    ...config,
  };
}

firebase.initializeApp(defaultconfig);
//firebase.firestore().settings(settings);

export const log_db = firebase.initializeApp(logconfig, "log_db");

export const reports_db = firebase.initializeApp(reportsconfig, "reports_db");

export default firebase;
