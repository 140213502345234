
export const panelLayout = {
  xs: 24,
  sm: 24,
  md: 16,
  lg: 12,
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 18 },
  },
};

export const buttonItemLayout = {
  wrapperCol: { offset: 8 },
};

