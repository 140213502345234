import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateStringFormat } from 'utils/Globals';
import dayjs from 'dayjs';
import { ReceiptModel } from 'model/ReceiptModel';

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: 36,
    justifyContent: 'flex-end',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  invoiceDate: {
    display: 'flex',
  },
  label: {
    width: 'auto',
    paddingRight: 14,
    fontWeight: 'bold',
  },
});

export type InvoiceNoProps = {
  receipt: ReceiptModel;
};

const InvoiceNo = ({ receipt }: InvoiceNoProps) => {
  const formattedDate = dayjs(receipt.DateNum).format(dateStringFormat);

  return (
    <Fragment>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Receipt No:</Text>
        <Text style={styles.invoiceDate}>{receipt.RefNum}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>Date: </Text>
        <Text>{formattedDate}</Text>
      </View>
    </Fragment>
  );
};

export default InvoiceNo;
