import React, { useState } from "react";
import { Input, Table } from "antd";
import { useNavigate } from "react-router";
import { ExpenseListProps } from "utils/types";
import ItemModel from "model/ItemModel";
import useStyles from "components/style";
import ExpenseModel from "model/ExpenseModel";
import dayjs from "dayjs";
import { dateStringFormat, dateStringFormatShort, ExpenseType } from "utils/Globals";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function ExpenseList(props: ExpenseListProps) {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState(null);
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:400px)');

  const columns: any = [
    {
      title: "Index",
      key: "index",
      width: "5%",
      responsive: ['lg'],
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Bill Num",
      dataIndex: "Billnum",
      key: "Billnum",
      width: "10%",
      sorter: {
        compare: (a, b) => a.Billnum - b.Billnum,
      },
    },
    {
      title: "Date",
      //  dataIndex: "Date",
      key: "date",
      defaultSortOrder: "descend",
      // sorter: {
      //   compare: (a, b) => a.DateNum - b.DateNum,
      // },
      render: (text, record: ExpenseModel, index) => {
        if (!record.Date) return "";
        return dayjs(record.Date).format(matches ? dateStringFormat: dateStringFormatShort);
      },
      sorter: {
        compare: (a: ExpenseModel, b: ExpenseModel) => {
          if (a.Date && b.Date) return a.Date - b.Date;
          if (!a.Date && !b.Date) return 0;
          if (!a.Date) return -1;
          if (!b.Date) return 1;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: "10%",
      responsive: ['sm'],
      defaultSortOrder: "ascend",
      render: (text, record:ExpenseModel, index) => ExpenseType.toString(record.Type),
      sorter: {
        compare: (a, b) => a.Type - b.Type,
      },
    },
    // {
    //   title: "Purchase Price",
    //   dataIndex: "PurchasePrice",
    //   key: "pp",
    // },
    {
      title: "Reference",
      dataIndex: "Reference",
      responsive: ['lg'],
      key: "sp",
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      width: "20%",
      key: "Amount",
      render: (text) => <b>{text}</b>,
    },
  ];

  // exclude Id and Date from Items data
  function omit(obj) {
    const { Id, Date, ...rest } = obj;
    return rest;
  }

  const Search = (value) => {
    let v = value.target.value;
    const baseData = props.expenses;

    const filterTable = baseData.filter((o) =>
      Object.keys(omit(o)).some((k) =>
        String(o[k]).toLowerCase().includes(v.toLowerCase())
      )
    );

    setFiltered(filterTable);
  };

  return (
    <>
      <Input.Search
        placeholder="Search expense..."
        onChange={Search}
        className={classes.padded}
      />

      <Table
        dataSource={filtered ? filtered : props.expenses}
        sortDirections={["ascend", "descend", "ascend"]}
        columns={columns}
        pagination={{
          onChange(current) {
            setPage(current);
          },
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200"],
        }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              navigate("/app/addexpense", { state: record });
            },
          };
        }}
      />
    </>
  );
}
