import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import PrivateRoute from 'route/PrivateRoute';
import LoginPage from 'components/Login';
import { getIsLoggedIn } from 'utils/Globals';
import AppRoutes from 'route/Routes';
import store from 'redux/store';
import { Provider } from 'react-redux';
import Logger from 'utils/GlobalLogger';
import theme from 'AppTheme';
import { ThemeProvider } from '@material-ui/core/styles';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Logger>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>

          {/* <BrowserRouter>
      <Switch>
      <Route exact path="/" component={App} />
      
        <PrivateRoute
          exact
          path="/user"
          component={Dashboard}
          authenticated={getIsLoggedIn()}
        />
        <Route
          exact
          path="/items"
          component={Items}
          authenticated={getIsLoggedIn()}
        />
        <Redirect to="/err" />
      </Switch>
    </BrowserRouter> */}
        </React.StrictMode>
      </Logger>{' '}
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
