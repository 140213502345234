import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { themeColor } from 'utils/Globals';

const borderColor = '#90e5fc';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
   // borderBottomColor: '#bff0fd',
    backgroundColor: themeColor,
    color: '#fff',
    //  borderBottomWidth: 1,
  //  paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    alignItems: 'center',
    //   height: 24,
    textAlign: 'left',
    fontWeight: 'bold',
   // fontStyle: 'bold',
    flexGrow: 1,
    display: 'flex',
  },
  description: {
    width: '60%',
    //   borderRightColor: borderColor,
    //   borderRightWidth: 1,
  },
  qty: {
    width: '10%',
    textAlign: 'center',
    //  borderRightColor: borderColor,
    //  borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    textAlign: 'center',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  amount: {
    textAlign: 'center',
    width: '15%',
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Item Description</Text>
    <Text style={styles.qty}>Qty</Text>
    <Text style={styles.rate}>Rate</Text>
    <Text style={styles.amount}>Amount</Text>
  </View>
);

export default TableHeader;
