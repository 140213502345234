import React from 'react';
import './deleteaccount.css'; // Import the CSS file

export default function DeleteAccount() {

  return (
    <div className="center">
      <button
        onClick={() =>
          window.location.href = `mailto:info@droidbee.com?subject=${
            encodeURIComponent("Delete account on Igloo POS") || ''
          }&body=${encodeURIComponent("")}`
        }
      >
        Delete Account
      </button>
    </div>
  );
}
