import StockHistoryDto from 'dto/StockDto';
import firebase from 'firebase';
import StockHistoryModel from 'model/StockModel';
import { StockHistoryTable } from 'utils/Constants';
import { reports_db } from 'utils/Firebase';
import { StockDtoToModel, StockModelToDto } from './ItemRepo';
import { dateFormatMMM_YY } from "utils/Globals";
import dayjs from 'dayjs';
import { Result } from 'services/ServiceBase';

function StockHistoryDtoToModel(dto: StockHistoryDto) {
  const model = StockDtoToModel(dto);
  let sModel = new StockHistoryModel();
  sModel = { ...model, amount: dto.a, date: dto.d };

  return sModel;
}

function StockHistoryModelToDto(model: StockHistoryModel) {
  const dto = StockModelToDto(model);
  let sDto = new StockHistoryDto();
  sDto = { ...dto, a: model.amount, d: model.date };

  return sDto;
}

export async function SaveStockHistory(itemid: string, stockHistory: StockHistoryModel, callback?) {
  const raw = StockHistoryModelToDto(stockHistory);
  // to remove undefined fields which give error in firebase DB save
  const js = JSON.parse(JSON.stringify(raw));

  const stockRef = firebase.database(reports_db).ref(StockHistoryTable());

  try {
    //stockHistory.Id = stockRef.push().key;

    const mmyy = dayjs().format(dateFormatMMM_YY);
    const date = dayjs().unix().toString();

    await stockRef.child(mmyy).child(itemid).child(date).set(js, (err) => {
      if (err && callback) callback(err); // if no permission
    });

    if (callback) callback(Result.SUCCESS);
  } catch (err) {
    if (callback) callback("fail");
  }
}
