import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { getCompanyData } from 'redux/selectors';
import { Edition } from 'utils/Globals';

const PaidCustomer = () => {
  const companydata = useAppSelector(getCompanyData);
  const navigate = useNavigate();

  // disallow content for FREE customers
  if (companydata.edition === undefined) return 'Loading Data';

  if (companydata.edition === 10) {
    setTimeout(() => {
      navigate('/');
    }, 0);
    // this is shown only momentarily, dont show any useful message here, or give a reasonable delay
    return 'Not allowed on Free Plan';
  }

  return <Outlet />;
};

export default PaidCustomer;
