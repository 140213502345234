import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import CompanyModel from 'model/CompanyModel';
import { themeColor } from 'utils/Globals';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    display: 'flex',
    marginTop: 24,
    backgroundColor: '#fff'
  },
  reportTitle: {
    color: themeColor,
    letterSpacing: 4,
    fontSize: 25,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  titleNormal: {
    color: themeColor,
    letterSpacing: 1,
    fontSize: 13,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

type TitleProps = {
  company: CompanyModel;
}

const Title = ({ company } : TitleProps) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{company.name}</Text>
    <Text style={styles.titleNormal}>{company.address}</Text>
    <Text style={styles.titleNormal}>{company.primaryEmail}</Text>
    <Text style={styles.titleNormal}>{company.phone}</Text>
  </View>
);

export default Title;
