import ServiceBase from "services/ServiceBase";
import PartyModel from "model/PartyModel";
import { LoadPartiesRepo, SavePartyRepo } from "repo/PartyRepo";
import { setParties } from "redux/reducers/rootReducer";

export class LoadPartiesService extends ServiceBase {
  action() {
    LoadPartiesRepo((partyList) => {
      this.setdataLoaded(partyList);
      this.dispatch(setParties(partyList)) // save in redux also
    })
  }
}

export class SavePartyService extends ServiceBase {
  action(party: PartyModel) {
    SavePartyRepo(party, (result) => this.callback(result));
  }
}
