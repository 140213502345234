
export default class ExpenseDto {
    public id: string;

    public _d : number;

    public bn : number;

    public t: number;

    public am: number;

    public r: string;

    public n : string;

}