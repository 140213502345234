import { ReceiptDto } from "dto/ReceiptDto";
import firebase from "firebase";
import { receiptDtoToModel } from "repo/ReceiptRepo";
import { ReceiptTable } from "utils/Constants";

export default function LoadProfitLossRepo(stDate, endDate, callback?, error?) {
  const recptRef = firebase.database().ref(ReceiptTable());
  recptRef
    .orderByChild("_d")
    .startAt(stDate)
    .endAt(endDate)
    .once(
      "value",
      (snapshot) => {
        const plData = [];
        snapshot.forEach((childSnapshot) => {
          const row: ReceiptDto = childSnapshot.val();
          row.id = childSnapshot.key
          if (row.st === 10) plData.push(receiptDtoToModel(row));
        });

        if (callback) callback(plData);
      },
      (err) => {
        if (error) error(err);
      }
    );
}
