import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { LoadLastsXDaysReceipts } from "repo/ReceiptPaymentRepo";
import { LoadRecentReceipts } from 'repo/ReceiptRepo'

export class LastXDaysReceiptPaymentLoader extends ServiceBase {
  private receiptsRef: firebase.database.Reference;

  action() {
    this.receiptsRef = LoadLastsXDaysReceipts(
      (list) => {
        this.setdataLoaded(true);
        this.callback(list);
      },
      (err) => this.handleError(err)
    );
  }

  cleanup(){
    this.receiptsRef.off()
  }
}

export class LoadRecentReceiptsService extends ServiceBase {
  action() {
    LoadRecentReceipts(10,
      (list) => {
        this.setdataLoaded(list);
        this.callback(list);
      },
      (err) => this.handleError(err)
    );
  }
}

