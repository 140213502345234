export default class PartyModel {
  public Id: string;
  public DueAmount: number;
  public IsCust: number;
  public IsVendor: number;
  public Name: string;
  public Person: string;
  public Address: string;
  public Phone: string;
}
