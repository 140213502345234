import { ReceiptModel } from 'model/ReceiptModel';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { saveProfitLossData } from 'redux/reducers/rootReducer';
import ProfitLossService from 'services/ProfitLossService';
import useFetcher from 'utils/useFetcher';
import { ExpenseValueType, PlDataType } from 'utils/types';
import { getExpenseMonthly, getPlData } from 'redux/selectors';
import { LoadExpenseOfMonthService } from 'services/ExpenseService';

const useProfitLossHandler = function () {
  const [totSales, setTotSales] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [purchVal, setPurchVal] = useState(0);
  // const [profit, setProfit] = useState(0);
  const [loadData, data, setdataLoaded] =
    useFetcher<ReceiptModel[]>(ProfitLossService);
  const [loadExpenses] = useFetcher(LoadExpenseOfMonthService);
  const dispatch = useAppDispatch();
  const [startDate, setStDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const getPldataFromRedux = useAppSelector<PlDataType>((state) =>
    getPlData(state, startDate)
  );
  const getExpenseFromRedux = useAppSelector<ExpenseValueType>((state) =>
    getExpenseMonthly(state, startDate)
  );

  const alreadyLoadedData = () => {
    if (getPldataFromRedux !== undefined) {
      const loadedData = getPldataFromRedux;
      setTotSales(loadedData.sales);
      setPurchVal(loadedData.pprice);
      //  setProfit(loadedData.profit);
      setdataLoaded(loadedData.receipts);
      return true;
    }

    return false;
  };

  useEffect(() => {
    alreadyLoadedData();
  }, [getPldataFromRedux]);

  useEffect(() => {
    if (getExpenseFromRedux) setExpenses(getExpenseFromRedux.expenses);
  }, [getExpenseFromRedux]);

  useEffect(() => {
    if (startDate === 0) return;

    if (alreadyLoadedData()) return;

    loadData(
      (result) => {
        if (result == null) {
          setdataLoaded([]);
          return;
        }
        let sale = 0;
        let purchasecost = 0;
        let _profit = 0;
        result.forEach((element: ReceiptModel) => {
          sale += element.PaidAmount;
          if (element.TotalPurchPrice !== undefined)
            purchasecost += element.TotalPurchPrice;
        });
        //  _profit = sale - purchasecost;

        setTotSales(sale);
        setPurchVal(purchasecost);
        //   setProfit(_profit);
        setdataLoaded(result);
        const pldata: PlDataType = {
          key: startDate,
          //    expenses: 0,
          pprice: purchasecost,
          receipts: result,
          //      profit: _profit,
          sales: sale,
        };
        dispatch(saveProfitLossData(pldata)); // we can use straight away, but we persist it in
        // redux for caching once loaded data
      },
      startDate,
      endDate
    );
  }, [startDate]);

  useEffect(() => {
    if (startDate === 0) return;

    if (!getExpenseFromRedux) loadExpenses((result) => {}, startDate, endDate);
  }, [startDate]);

  const loadPLData = (stDate, _endDate) => {
    setStDate(stDate);
    setEndDate(_endDate);
  };

  return [loadPLData, data, totSales, purchVal, expenses] as const;
};

export default useProfitLossHandler;
