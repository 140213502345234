import React from 'react';
import { Form, Input, InputNumber, Col, Row } from 'antd';
import {
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Box,
} from '@material-ui/core';
import DatePicker from 'components/utility/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import useStyles from 'components/style';
import { makeStyles } from '@material-ui/core';
import "./item.css"
import "antd/dist/antd.css";

const { RangePicker } = DatePicker;

export default function StockCard(props) {
  const { item, addClicked, onQuantityChanged } = props;
  const classes = useStyles();

  return (
    <Card title="Stock" >
      <CardHeader
        subheader="Stock details"
        title="Stock"
        action={
          props.addClicked && (
            <IconButton>
              <AddIcon
                onClick={() => {
                  addClicked();
                }}
              />
            </IconButton>
          )
        }
      />
      <Divider />
      <CardContent>
        <Form.Item
          rules={[{ required: false }]}
          name="quantity"
          label="Quantity"
        >
          <InputNumber
            style={{ width: '50%' }}
            placeholder="Quantity"
            id="quantity"
            onChange={onQuantityChanged}
          />
        </Form.Item>

        <Form.Item rules={[{ required: false }]} name="batch" label="Batch">
          <Input placeholder="Batch" id="batch" value={item.Stock?.Batch} />
        </Form.Item>

        <Form.Item
          rules={[{ required: false }]}
          name="mdate"
          label="Mfg Date"
        >
          <RangePicker
            placeholder={['Mfg Date', 'Exp Date']}
            id="mdate"
            // defaultValue={[
            //   dayjs(item.Stock.MfgDate),
            //   dayjs(item.Stock.ExpDate),
            // ]}
          />
        </Form.Item>

        <Form.Item
          rules={[{ required: false }]}
          name="location"
          label="Location"
        >
          <Input placeholder="Location" id="loc" />
        </Form.Item>
      </CardContent>
    </Card>
  );
}
