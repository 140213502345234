import React from "react";
import clsx from "clsx";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import { SimpleCardViewProps } from "utils/types";

const useStyles = props => makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: props.iconColor || colors.orange[500],
    height: 56,
    width: 56,
  },
}));

const SimpleCardView = (props: SimpleCardViewProps) => {
  const classes = useStyles(props)();

  return (
    <Card className={clsx(classes.root, props.className)}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {props.title}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {props.text}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>{props.icon}</Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SimpleCardView;
