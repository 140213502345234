import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
} from '@material-ui/core';
import { Row, Col, Form, Divider, Input, Button, Select, InputNumber } from 'antd';
import ExpenseModel from 'model/ExpenseModel';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { panelLayout, formItemLayout, buttonItemLayout } from 'utils/Styles';
import CloseIcon from '@material-ui/icons/Close';
import { ExpenseType } from 'utils/Globals';
import useStyles from 'components/style';
import dayjs from 'dayjs';
import DatePicker from 'components/utility/DatePicker';
import { SaveExpenseRepo } from 'repo/ExpenseRepo';
import { useSender } from 'utils/useFetcher';
import { SaveExpenseService } from 'services/ExpenseService';
import { useAppDispatch } from 'redux/hooks';
import { updateExpense } from 'redux/reducers/rootReducer';

const { Option } = Select;

const AddEditExpense = () => {
  const { state } = useLocation();
  const [expense, setExpense] = useState<ExpenseModel>(
    state ? (state as ExpenseModel) : new ExpenseModel()
  );
  const [saveExpense] = useSender(SaveExpenseService);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const editedExpense = useCallback(() => {
    dispatch(updateExpense(expense))
  }, [dispatch])

  const handleFinish = (values) => {
    expense.Amount = values.amount;
    expense.Billnum = values.bill;
    expense.Date = values.date.valueOf();
    expense.Notes = values.notes;
    expense.Reference = values.ref;
    if(values.type) expense.Type = values.type;

    saveExpense(result => {
      editedExpense()
    }, expense)
    //SaveExpenseRepo(expense);
    navigate(-1);
  };

  const getOptions = () => {
    const options = [];
    for (const item in ExpenseType) {
      if (Number(item)) {
        options.push(
          <Option value={Number(item)}>
            {ExpenseType.toString(Number(item))}
          </Option>
        );
      }
    }

    return options;
  };

  return (
    <Row justify="center" className="item">
      <Col {...panelLayout}>
        {/* <Card title="Item Details" className="card"> */}
        <Form
          onFinish={handleFinish}
          {...formItemLayout}
          initialValues={{
            amount: expense.Amount,
            date: expense.Date ? dayjs(expense.Date) : dayjs(),
            bill: expense.Billnum,
            ref: expense.Reference,
            notes: expense.Notes,
            //  type: ExpenseType.toString(expense.Type),
          }}
        >
          <Card>
            <CardHeader
              title="Expense Details"
              subheader=""
              action={
                <IconButton>
                  <CloseIcon onClick={() => navigate(-1)} />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <Form.Item
                rules={[{ required: true }]}
                name="amount"
                label="Amount"
              >
                <InputNumber placeholder="Amount" id="amount" />
              </Form.Item>

              <Form.Item rules={[{ required: true }]} name="date" label="Date">
                <DatePicker placeholder="Date" id="date" />
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="bill"
                label="Bill Num"
              >
                <InputNumber placeholder="Bill Number" id="bill" />
              </Form.Item>

              <Form.Item rules={[{ required: false }]} name="type" label="Type">
                <Select
                  placeholder="Select type"
                  defaultValue={expense.Type}
                  allowClear
                >
                  {getOptions()}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="ref"
                label="Reference"
              >
                <Input placeholder="Reference" id="ref" />
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="notes"
                label="Notes"
              >
                <Input placeholder="Notes" id="notes" />
              </Form.Item>

              <Box display="flex" justifyContent="flex-end" p={2}>
                <Form.Item {...buttonItemLayout}>
                  <Button
                    className={classes.margin}
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Box>
            </CardContent>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default AddEditExpense;
