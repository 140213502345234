import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { isLoggedIn } from "redux/selectors";
import LoggingService from "services/LoggingService";
import { LogMessageType } from "./Globals";

type CallbackType = (tag: number, message?: LogMessageType) => void;

const useLogger = () => {
  const [logger, setLogger] = useState<LoggingService>(null);
  const _isLoggedIn = useAppSelector(isLoggedIn);

  useEffect(() => {
    if (!logger && _isLoggedIn) setLogger(new LoggingService());
  }, [_isLoggedIn]);

  const log = useCallback<CallbackType>(
    (tag, message) => {
      if (logger) logger.log(tag, message);
    },
    [logger]
  );

  const logSessionEnd = useCallback(() => {
    if (logger) logger.logSessionEnd();
  }, [logger]);

  return [log, logSessionEnd] as const;
};

export default useLogger;
