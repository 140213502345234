export default class PartyDto {
  public id: string;
  public dueAmount: number;
  public isCust: number;
  public isVendor: number;
  public name: string;
  public person: string;
  public address: string;
  public phone: string;
}
