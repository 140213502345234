import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, Input, InputNumber, Col, Button, Row, Card } from 'antd';
import { SaveItemService } from 'services/ItemService';
import DatePicker from 'components/utility/DatePicker';
import ItemModel from 'model/ItemModel';
import { useSender } from 'utils/useFetcher';
import './item.css';
import dayjs from 'dayjs';
import useStyles from 'components/style';
import {
  Card as MCard,
  Button as MButton,
  CardContent,
  CardHeader,
  Divider,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { panelLayout, formItemLayout, buttonItemLayout } from 'utils/Styles';
import StockCard from './StockCard';
import AddStock from './AddStock'

export function getMfgDate(item) {
  return item.Stock?.MfgDate && dayjs.unix(item.Stock.MfgDate);
}

export function getExpDate(item) {
  return item.Stock?.ExpDate && dayjs.unix(item.Stock.ExpDate);
}

export default function AddEditItem(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState<ItemModel>(
    state ? (state as ItemModel) : new ItemModel()
  );
  const classes = useStyles();

  const formRef = useRef(null);
  const [saveItemService] = useSender(SaveItemService);
  const [showAddStock, setShowAddStock] = useState(false)

  const handleFinish = (values) => {
    console.log(values);

    //let item = new ItemModel();
    item.Date = dayjs().unix();
    item.Name = values.name;
    item.Sku = values.sku;
    item.Desc = values.desc;

    if (values.mdate[0]) {
      item.Stock.MfgDate = values.mdate[0].unix();
      item.Stock.ExpDate = values.mdate[1].unix();
    }

    item.PurchasePrice = values.pprice;
    item.SalePrice = values.sprice;

    if (values.quantity) item.Stock.ItemCount = values.quantity;
    if (values.batch) item.Stock.Batch = values.batch;
    if (values.location) item.Stock.Location = values.location;
    //  item.brandId = values.brand;
    //  item.categotyId = values.category;
    item.isActive = 1;
    saveItemService(null, item);

    navigate(-1); // go to previous page
  };

  const addClicked = () => {
    setShowAddStock(true)
  }

  console.log('sku = ', item.Sku);
  return (
    <>
      <Row justify="center" className="item">
        <Col {...panelLayout}>
          {/* <Card title="Item Details" className="card"> */}
          <Form
            ref={formRef}
            onFinish={handleFinish}
            {...formItemLayout}
            initialValues={{
              sku: item.Sku,
              name: item.Name,
              desc: item.Desc,
              //    category: item.categotyId,
              //    brand: item.brandId,
              pprice: item.PurchasePrice,
              sprice: item.SalePrice,
              quantity: item.Stock?.ItemCount,
              batch: item.Stock?.Batch,
              mdate: [getMfgDate(item), getExpDate(item)],
              location: item.Stock?.Location,
            }}
          >
            <MCard>
              <CardHeader
                subheader="Item details"
                title="Item"
                action={
                  <IconButton>
                    <CloseIcon onClick={() => navigate(-1)} />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                <Form.Item rules={[{ required: false }]} name="sku" label="SKU">
                  <Input placeholder="SKU" id="sku" />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true }]}
                  name="name"
                  label="Name"
                >
                  <Input placeholder="Item name" id="name" />
                </Form.Item>

                <Form.Item
                  rules={[{ required: false }]}
                  name="desc"
                  label="Description"
                >
                  <Input placeholder="Description" id="desc" />
                </Form.Item>

                <Form.Item
                  rules={[{ required: false }]}
                  name="pprice"
                  label="Purchase Price"
                >
                  <InputNumber
                    style={{ width: '50%' }}
                    placeholder="Purchase Price"
                    id="pprice"
                  />
                </Form.Item>

                <Form.Item
                  rules={[{ required: false }]}
                  name="sprice"
                  label="Sale Price"
                >
                  <InputNumber
                    style={{ width: '50%' }}
                    placeholder="Sale Price"
                    id="sprice"
                  />
                </Form.Item>

                <StockCard item={item} addClicked={addClicked} open={showAddStock}/>

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Form.Item {...buttonItemLayout}>
                    <Button
                      className={classes.margin}
                      type="primary"
                      htmlType="submit"
                    >
                      SAVE
                    </Button>
                  </Form.Item>
                </Box>
              </CardContent>
            </MCard>
          </Form>
          {/* </Card> */}
        </Col>
      </Row>
      <AddStock open={showAddStock} item={item} onClose={() => setShowAddStock(false)} />
    </>
  );
}
