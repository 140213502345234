import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { themeColor } from 'utils/Globals';

export default createMuiTheme({
  palette: {
    primary: {
      main: themeColor,
    },
    secondary: {
      main: '#e74c3c',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat'
  }
});
