import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableBlankRows from './TableBlankRows';
import TableFooter from './TableFooter';

const tableRowsCount = 10;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: 24,
  //  borderWidth: 1,
    display: 'flex',
  //  borderColor: '#bff0fd',
  },
});

const ReceiptItemsTable = ({ receiptItems }) => (
  <View style={styles.tableContainer}>
    <TableHeader />

    <TableRow items={receiptItems} />
    <TableFooter items={receiptItems} />
    <TableBlankRows rowsCount={receiptItems.length < tableRowsCount ? tableRowsCount - receiptItems.length : 3} />
  </View>
);

export default ReceiptItemsTable;
