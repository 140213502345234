import { StoreMallDirectory } from '@material-ui/icons'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import rootReducer, { initialState } from './reducers/rootReducer'

const combinedReducer = combineReducers({
  root: rootReducer,
});

const mainReducer = (state, action) => {
  if (action.type === 'root/logout') { // check for action type 
    state = [];
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: mainReducer
})


// const store = configureStore({
//   reducer: {
//       root: rootReducer,
//   }
// })

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch