import { PartyTable } from "utils/Constants";
import ServiceBase from "services/ServiceBase";
import firebase from "utils/Firebase";
import PartyDto from "dto/PartyDto";
import PartyModel from "model/PartyModel";

// export class Party {
//   private _id: string;
//   public get Id(): string {
//     return this._id;
//   }
//   public set Id(v: string) {
//     this._id = v;
//   }

//   private name: string;
//   public get Name(): string {
//     return this.name;
//   }
//   public set Name(v: string) {
//     this.name = v;
//   }

//   private dueAmount: number;
//   public get DueAmount(): number {
//     return this.dueAmount;
//   }
//   public set DueAmount(v: number) {
//     this.dueAmount = v;
//   }

//   private isCust: number;
//   public get IsCust(): number {
//     return this.isCust;
//   }
//   public set IsCust(v: number) {
//     this.isCust = v;
//   }

//   private isVendor: number;
//   public get IsVendor(): number {
//     return this.isVendor;
//   }
//   public set IsVendor(v: number) {
//     this.isVendor = v;
//   }
// }

export function partyDtoToModel(dto: PartyDto) {
  let p: PartyModel = new PartyModel();
  if (dto == null) return null;
  p.Id = dto.id
  p.DueAmount = dto.dueAmount;
  p.IsCust = dto.isCust;
  p.IsVendor = dto.isVendor;
  p.Name = dto.name;
  p.Address = dto.address;
  p.Person = dto.person;
  p.Phone = dto.phone;

  return p;
}

export let PartyList: PartyModel[] = [];

export function SavePartyRepo(party: PartyModel, error?) {
  //    const des = deserialize<Item>(newItem, Item);

  let p: PartyDto = new PartyDto();
  p.dueAmount = party.DueAmount
  p.isCust = party.IsCust;
  p.isVendor = party.IsVendor
  p.name = party.Name 
  // item.Name = newItem.: n
  // item.salePrice = newItem.sp
  // item.purchasePrice = newItem.pp

  PartyList.push(party);

  let partyRef = firebase.database().ref(PartyTable());
  let key = partyRef.push().key;
  partyRef.child(key).set(p, err => {
    if(error) error(err)
  })

  return key;
}

export function LoadPartiesRepo(callback?) {
  const dbRef = firebase.database().ref(PartyTable());
  dbRef.once("value", snapshot => {
      PartyList = []; // clear .. new Array
      snapshot.forEach(childSnapshot => {
        // key will be "ada" the first time and "alan" the second time
        const key = childSnapshot.key;
        // childData will be the actual contents of the child
        const childData: PartyDto = childSnapshot.val();
        childData.id = key;
        PartyList.push(partyDtoToModel(childData));
      });

      if(callback) callback(PartyList)
      //ItemList = snapshot.val()
    });
  
}

