import React, { useEffect } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import useFetcher from 'utils/useFetcher';
import PartyModel from 'model/PartyModel';
import { useAppSelector } from 'redux/hooks';
import { LoadPartiesService } from 'services/PartyService';

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    marginTop: 0,
    display: 'flex',
  },
  billTo: {
    flexDirection: 'column',
    marginLeft: 10,
    paddingBottom: 3,
    display: 'flex',
    lineHeight: 1.3,
    fontWeight: 'bold',
  },
  billDetails: {
    flexDirection: 'column',
    marginLeft: 10,
    paddingBottom: 3,
    display: 'flex',
    lineHeight: 1.3,
  },
});

export type AddressProps = {
  party: PartyModel;
};

const Address = ({ party }: AddressProps) => {
  if(party == null) return null;

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.billTo}>Bill To:</Text>
      <View style={styles.billDetails}>
        <Text>{party.Name}</Text>
        <Text>{party.Person}</Text>
        <Text>{party.Address}</Text>
        <Text>{party.Phone}</Text>
      </View>
    </View>
  );
};

export default Address;
