export default class ItemModel {
  public Id: string;
  public Sku: string;
  public Name: string;
  public SalePrice: number;
  public PurchasePrice: number;
  public Desc: string;
  public isActive: number;
  public parentId: string;
  public hasSubitems: string;
  public Date: number;
  public categotyId: string
  public brandId: string
  
  public Stock: StockModel = new StockModel();
}

export class StockModel {
  //  public Quantity: number;
  public Location: string;
  public Cartons: number;
  public ItemCount: number;
  public ItemsPerCarton: number;
  public Batch: string;
  public MfgDate: number;
  public ExpDate: number;
}
