import dayjs from "dayjs";
import firebase from "firebase";
import {
  LogSessionEventTable,
  LogSessionTable,
  LogUserTable,
} from "utils/Constants";
import { log_db } from "utils/Firebase";
import { LogEvent, LogMessageType } from "utils/Globals";
import { LOG_EXCEPTION } from "utils/LogConstants";

let logUserRef = firebase.database(log_db).ref(LogUserTable());
let logSessionRef = firebase.database(log_db).ref(LogSessionTable());
let logSessionEventRef = firebase.database(log_db).ref(LogSessionEventTable());

class LogUserModel {
  public email: string;
  public lasteventdate: number;
  public phone: string;
  public sessioncount: number;
  public shop: string;

  public static LastEventDate: string = "l";
  public static SessionCount: string = "x";
}

class LogUserDto {
  public e: string; // email
  public l: number; //lasteventdate
  public p: string; //phone
  public s: number; // sessioncount
  public h: string; // shop
}

class LogSessionModel {
  public eventcount: number;
  public sessionenddate: number;
  public startdate: number;
  public hasexception: boolean;
  public isWeb: boolean

  public static EventCount: string = "c";
  public static HasException: string = "e";
  public static SessionEndDate: string = "z";
  public static StartDate: string = "s";
  public static isWeb: string = 'w';
}

class LogSessionEventModel {
  public date: number;
  public msg: LogMessageType;
  public tag: number;
}

class LogSessionEventDto {
  public d: number;
  public m: LogMessageType;
  public t: number;
}

function convertSessionEventModelToDto(model: LogSessionEventModel) {
  let event: LogSessionEventDto = new LogSessionEventDto();
  event.d = model.date;
  event.t = model.tag;
  event.m = model.msg;

  return event;
}

export function getLogSessionId() {
  return logSessionRef.push().key;
}

function getEvent(tag, message) {
  let event: LogSessionEventModel = new LogSessionEventModel();

  event.date = dayjs().unix(); // date
  event.tag = tag; // tag
  event.msg = message; //message

  return JSON.parse(JSON.stringify(convertSessionEventModelToDto(event)));
}

export async function LogMessageRepo(sessionId, logEventIndex, tag, message) {
  let event = getEvent(tag, message);

  logSessionRef
    .child(sessionId)
    .child(LogSessionModel.EventCount)
    .set(logEventIndex); //eventcount
  logSessionEventRef
    .child(sessionId)
    .child(logEventIndex.toString())
    .set(event);
  logUserRef.child(LogUserModel.LastEventDate).set(event.d); // lasteventdate

  if (event.tag === LOG_EXCEPTION)
    logSessionRef
      .child(sessionId)
      .child(LogSessionModel.HasException)
      .set(true); //hasexception
}

export function LogSessionEndRepo(sessionId) {
  logSessionRef
    .child(sessionId)
    .child(LogSessionModel.SessionEndDate)
    .set(dayjs().unix()); //sessionenddate
}

export function LogSessionStartRepo(sessionId) {
  let date = dayjs().unix() * 1000; // ms

  // todo.. combine these
  logSessionRef.child(sessionId).child(LogSessionModel.StartDate).set(date, complete => console.log('sessionref = ', complete)); //startdate
  logSessionRef.child(sessionId).child(LogSessionModel.isWeb).set(1); //is web

  logUserRef.child(LogUserModel.LastEventDate).set(date); //lasteventdate

  logUserRef.once("value", (snapshot) => {
    let log = snapshot.val();
    if (log.x === undefined) log.x = 0;
    log.x++;
    logUserRef.child(LogUserModel.SessionCount).set(log.x);
  });
}
