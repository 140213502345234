import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import useProfitLossHandler from 'components/reports/pl/useProfitLossHandler';
import dayjs from 'dayjs';
import { ReceiptModel } from 'model/ReceiptModel';
import SimpleCardView from 'components/reports/pl/SimpleCardView';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptsListView from 'components/receipt/ReceiptsListView';
import { blue, red, green } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import defColumns from 'business/receipt/ReceiptTableColums';
import useStyles from 'components/style';

const ProfitLoss = () => {
  const [loadPL, data, totSales, purchVal, expenses] =
    useProfitLossHandler();
  const [isloaded, setIsLoaded] = useState(false);
  const classes = useStyles();
  const [date, setDate] = useState(dayjs());
  const [profit, setProfit] = useState(0)

  useEffect(() => {
    setProfit(totSales - purchVal - expenses)
  }, [totSales, purchVal, expenses])

  useEffect(() => {
    //  const date = new Date();
    setIsLoaded(false);
    const firstDay = date.startOf('month'); //new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = date.endOf('month'); //new Date(date.getFullYear(), date.getMonth() + 1, 1);

    loadPL(firstDay.valueOf(), lastDay.valueOf());
  }, [date]);

  useEffect(() => {
    if (data) setIsLoaded(true);
  }, [data]);

  const prevMonth = () => {
    //  date.setMonth(date.getMonth() -1)

    setDate(date.subtract(1, 'month'));
  };

  const nextMonth = () => {
    //   date.setMonth(date.getMonth() +1)

    setDate(date.add(1, 'month'));
  };

  const customColumns = (page) => {
    const _columns = defColumns(page);

    _columns.push();
    return _columns;
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.paddingBottom}
      >
        <IconButton className={classes.margin} onClick={prevMonth}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography color="textPrimary" variant="h4">
          Profit/Loss during {date.format('MMM-YYYY')}
        </Typography>
        <IconButton className={classes.margin} onClick={nextMonth}>
          <ChevronRightIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <SimpleCardView
            title="Total Sales"
            text={totSales.toLocaleString()}
            iconColor={blue[600]}
            icon={<AttachMoneyIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <SimpleCardView
            title="Expenses"
            text={expenses.toLocaleString()}
            icon={<AttachMoneyIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <SimpleCardView
            title="Purchase Price"
            text={purchVal.toLocaleString()}
            iconColor={red[600]}
            icon={<AttachMoneyIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <SimpleCardView
            title="Profit/Loss"
            text={profit.toLocaleString()}
            iconColor={green[600]}
            icon={<AttachMoneyIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h6">
            Receipts during {date.format('MMM-YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReceiptsListView
            receipts={data}
            loading={!isloaded}
            columns={customColumns}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfitLoss;
