import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { ReceiptPaymentTable } from "utils/Constants";
import moment from "moment";
import ReceiptPaymentDto from "dto/ReceiptPaymentDto";
import ReceiptPaymentModel from "model/ReceiptPaymentModel";
import dayjs from "dayjs";
import { firebaseRefs } from "utils/Globals";

// export class ReceiptPaymentDto {
//   private receiptId: string;
//   public get ReceiptId(): string {
//     return this.receiptId;
//   }
//   public set ReceiptId(v: string) {
//     this.receiptId = v;
//   }

//   private _d: number;
//   public get Date(): number {
//     return this._d;
//   }
//   public set Date(v: number) {
//     this._d = v;
//     this._dateStr = moment(this._d).format("DD/MM/YYYY");
//   }

//   private _dateStr: string;
//   public get DateStr(): string {
//     return this._dateStr;
//   }

//   private _date: number; //date

//   // private _d: number; //date
//   public get DateNum(): number {
//     return this._d;
//   }
//   public set DateNum(v: number) {
//     this._d = v;
//   }

//   private a: number; // amount
//   public get Amount(): number {
//     return this.a;
//   }
//   public set Amount(v: number) {
//     this.a = v;
//   }
// }

function FirebaseToPOJO(r) {
  let recptpay: ReceiptPaymentModel = new ReceiptPaymentModel();
  // recptpay.Id = r.id;
  recptpay.Date = r._date;
  recptpay.DateNum = r._d;
  recptpay.Amount = r.a;
  recptpay.DateStr = dayjs(r._d).format("DD-MMMM");

  return recptpay;
}

function ReceiptPaymentPojoToFirebase(r: ReceiptPaymentModel) {
  let rp: ReceiptPaymentDto = new ReceiptPaymentDto();

  rp.id = r.ReceiptId;
  rp._d = r.DateNum;
  rp.a = r.Amount;

  return rp;
}

export function CreateReceiptPaymentModel() {
  let recptpay = new ReceiptPaymentModel();
  //  recptpay.Id = Id;
  //  recptpay.DateNum = moment(date).unix() * 1000;
  recptpay.DateNum = moment(moment().format()).unix() * 1000;
  // recptpay.Amount = Amount;
  return recptpay;
}

export function SaveReceiptPayment(rp: ReceiptPaymentModel, error?: any) {
  let recptItemRef = firebase.database().ref(ReceiptPaymentTable());
  let raw = ReceiptPaymentPojoToFirebase(rp);
  let js = JSON.parse(JSON.stringify(raw)); // remove undefined vals

  let id = recptItemRef.push().key;
  recptItemRef.child(id).set(js, (err) => {
    if (err) {
      if (error) error(err);
    }
  });
}

export let ReceiptPaymentsList: ReceiptPaymentModel[] = [];

export function LoadLastsXDaysReceipts(callback?, errorHandler?) {
  let ReceiptPaymentList: ReceiptPaymentDto[] = [];

  const receiptsRef = firebase.database().ref(ReceiptPaymentTable());
  let end = new Date();
  let start = new Date();
  start.setDate(end.getDate() - 7); // one week ago

  receiptsRef
    .orderByChild("_d")
    .startAt(start.getTime())
    .endAt(end.getTime())
    .once(
      "value",
      (snapshot) => {
        let list = []; // clear ... list cannot be reassigned as its imported from outside file
        snapshot.forEach(function (childSnapshot) {
          var key = childSnapshot.key;
          var childData = childSnapshot.val();

          let pojo = FirebaseToPOJO(childData);
          //         pojo.SetDateStr(pojo.Date)
          list.push(pojo);
        });
        //setdataLoaded(true);
        if (callback) callback(list);
      },
      (error) => {
        if (errorHandler) errorHandler(error);
      }
    );

  firebaseRefs.push(receiptsRef);

  return receiptsRef;
}

export class AllReceiptPaymentsLoader extends ServiceBase {
  action() {
    const receiptPayments = firebase.database().ref(ReceiptPaymentTable());
    receiptPayments.once("value", (snapshot) => {
      ReceiptPaymentsList.length = 0; // clear ... ItemList cannot be reassigned as its imported from outside file
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        ReceiptPaymentsList.push(FirebaseToPOJO(childData));
      });
      this.setdataLoaded(true);
    });

    firebaseRefs.push(receiptPayments)
  }
}
