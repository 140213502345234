export class ReceiptModel {
  public Id: string;
  public RefNum: string;
  public PartyId: string;
  public Date: string;
  public DateNum: number;
  public BillDiscount: number;
  public Sale: number = 0;
  public Ret: number = 0;
  public Net: number = 0;
  public isSale: number;
  public PaidAmount: number = 0;
  public TotalPurchPrice: number;
  public Status: number;
  public IsDiscPercent: boolean;
  public Remarks: string;
  public ItemRecvd: boolean;
}
