import { ItemSaleMonthlyLookupDto } from "dto/ItemReportDto";

export class ItemSaleMonthlyLookupModel {
  public quantity: number = 0;
  public value: number = 0;
  public purchaseQuantity: number = 0;
  public purchaseValue: number = 0;
}

export function itemSaleLookupDtoToModel(dto: ItemSaleMonthlyLookupDto) {
  let is: ItemSaleMonthlyLookupModel = new ItemSaleMonthlyLookupModel();
  if (dto == null) return null;
  is.quantity = dto.q;
  is.value = dto.v;
  is.purchaseQuantity = dto.pq;
  is.purchaseValue = dto.pv;

  return is;
}

export function itemSaleLookupModelToDto(model: ItemSaleMonthlyLookupModel) {
  let is: ItemSaleMonthlyLookupDto = new ItemSaleMonthlyLookupDto();
  is.q = model.quantity;
  is.v = model.value;
  is.pq = model.purchaseQuantity;
  is.pv = model.purchaseValue;

  return is;
}
