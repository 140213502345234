import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ReceiptItemModel } from 'model/ReceiptItemModel';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    //  borderBottomColor: '#bff0fd',
    //  borderBottomWidth: 1,
    alignItems: 'center',
    // height: 24,
    fontStyle: 'bold',
    display: 'flex',
  },
  description: {
    width: '60%',
    textAlign: 'left',
    //  borderRightColor: borderColor,
    //  borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: '10%',
    /// borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  rate: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

export type TableRowProps = {
  items: ReceiptItemModel[];
};

const TableRow = ({ items }: TableRowProps) => {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.SKU}>
      <Text style={styles.description}>{item.itemStr}</Text>
      <Text style={styles.qty}>{item.quantity}</Text>
      <Text style={styles.rate}>{item.itemPrice}</Text>
      <Text style={styles.amount}>
        {(item.netAmt).toFixed(2)}
      </Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
