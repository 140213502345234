import React, { useEffect, useState, useContext } from 'react';
import { useAppSelector } from 'redux/hooks';
import Button from 'antd/lib/button';
import ExpenseModel from 'model/ExpenseModel';
import { getAllExpenses, getExpenseMonthly, isExpenseLoaded } from 'redux/selectors';
import {
  AllExpensesLoader,
  LoadExpenseOfMonthService,
} from 'services/ExpenseService';
import useFetcher from 'utils/useFetcher';
import ExpensesList from 'components/expense/ExpensesList';
import { useNavigate } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import { ExpenseValueType } from 'utils/types';
import { Container, Typography, Button as MButton, IconButton, Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useStyles from 'components/style';
import { LogContext } from 'utils/GlobalLogger';
import { OPEN_EXPENSES } from 'utils/LogConstants';

const Expenses = () => {
  const expenses = useAppSelector(getAllExpenses);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
  const getExpenseFromRedux = useAppSelector<ExpenseValueType>((state) =>
    getExpenseMonthly(state, startDate.valueOf())
  );
  const [logger] = useContext(LogContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [loadExpenses] = useFetcher<ExpenseModel[]>(LoadExpenseOfMonthService);
  const classes = useStyles();

  useEffect(() => {
    const firstDay = startDate.startOf('month');
    const lastDay = startDate.endOf('month');

    logger(OPEN_EXPENSES)

    if (getExpenseFromRedux === undefined || !getExpenseFromRedux.isLoaded)
    loadExpenses(
      (done) => setIsLoaded(true),
      firstDay.valueOf(),
      lastDay.valueOf()
    );
  }, [startDate])

  const handleAddExpense = (e) => {
    navigate('/app/addexpense');
  };

  const prevMonth = () => {
    setStartDate(startDate.subtract(1, 'month'));
  };

  const nextMonth = () => {
    setStartDate(startDate.add(1, 'month'));
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.paddingBottom}
      >
        <IconButton className={classes.margin} onClick={prevMonth}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography color="textPrimary" variant="h4">
          Expenses during {startDate.format('MMM-YYYY')}
        </Typography>
        <IconButton className={classes.margin} onClick={nextMonth}>
          <ChevronRightIcon />
        </IconButton>
      </Box>

      <MButton
        type="button"
        color="primary"
        className={classes.margin}
        variant="contained"
        onClick={handleAddExpense}
      >
        Add Expense
      </MButton>
      {/* <Button
        type="primary"
        className={classes.margin}
        onClick={handleAddExpense}
      >
        Add Expense
      </Button>
      
       <Typography variant="h2" color="textSecondary">
        Hello world
      </Typography> */}
      <ExpensesList expenses={getExpenseFromRedux && getExpenseFromRedux.expenseList} loaded={isLoaded} />
    </Container>
  );
};

export default Expenses;
