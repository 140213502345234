export const LOG_EXCEPTION = 0

export const OPEN_EXPENSES = 1;
export const ADD_EXPENSE = 2;
export const EDIT_EXPENSE = 3;
export const FROM_D_FAB = 4;
export const OPENING_D_FAB = 5;
export const UPGRADE_IGLOO_POS_CLICKED = 6;
export const SWITCHING_TO_DASHBOARD = 7;
export const SWITCHING_TO_ITEMS_TAB = 8;
export const OPEN_DRAWER = 9;
export const OPEN_REMINDERS_UI = 10;
export const OPENING_WHATSAPP = 11;
export const STARTING_APP = 12;
export const ITEM_DELETE_CLICKED = 13;
export const ITEM_SEARCH_CLICKED = 14;
export const IMPORT_ITEMS = 15;
export const ITEM_EXPORT_CLICKED = 16;
export const STOCK_INFO_CLICKED = 17;
export const IMPORT_ERROR = 18;
export const IMPORT_FINISHED = 19;
export const OPENING_RECEIPT_PO = 20;
export const PAY_INSTALLMENT_CLICKED = 21;
export const DELETING_RECEIPT = 22;
export const PRINT_RECEIPT_CLICKED = 23;
export const SAVE_PDF_CLICKED = 24;
export const ERROR_IN_PRINTING_1 = 25;
export const ERROR_IN_PRINTING = 26;
export const PRINT_ERROR = 27;
export const ADD_RECEIPT = 28;
export const EDIT_RECEIPT = 29;
export const CHECKOUT_RECEIPT = 30;
export const SHOWING_ABOUT_DIALOG = 31;
export const ITEM_SALES = 32;
export const SIGNUP_DONE = 33;
export const OPEN_PARTY_HISTORY = 34;
export const OPENING_PO_LIST = 35;
export const PROFIT_LOSS_BY_MONTH = 36;
export const PROFIT_LOSS_BY_DAY = 37;
export const PROFIT_LOSS = 38;
export const RENEW_LICENSE_CLICKED = 39;
export const DAYBOOK = 40;
export const OPEN_SETTINGS_UI = 41;
export const ITEM_FAB_CLICKED = 42;
export const ITEMS_DELETED = 43;
export const IMPORTING_ITEMS = 44;
export const ADD_ITEM = 45;
export const EDIT_ITEM = 46;
export const RECEIPT_ITEM_OPENED = 47;
export const ADDING_RECEIPT_ITEM = 48;
export const MONTHLY_SALES_REPORT_OF = 49;
export const OPENING_SALE_RECEIPT_LIST = 50;
export const OPEN_PARTY_LIST = 51;
export const LOGIN = 66;
export const LOGIN_ATTEMP = 77;
export const OPEN_WEB = 84;

// below are for map properties (used in Android)
export const ITEM_COUNT = "52";
export const SALE_RECEIPTS = "53";
export const NEW_RECEIPTS = "54";
export const SKU = "55";
export const NAME = "56";
export const P_PRICE = "57";
export const S_PRICE = "58";
export const STOCK = "59";
export const NET_AMOUNT = "60";
export const SALE_AMOUNT = "61";
export const DISCOUNT = "62";
export const NUM_OF_ITEMS = "63";
export const USER = "64";
export const ERROR = "65";
export const DATE = "67";
export const CASH_HANDLED = "68";
export const PURCH_VALUE = "69";
export const SALE_VALUE = "70";
export const STOCK_ITEMS_ = "71";
export const ITEMS_ = "72";
export const SET_DEVICE_ID = "73";
export const MULTI_DEVICE_ATTEMPTED = "74";
export const LOGIN_DISABLED = "75";
export const SUCCESS = "76";
export const MONTH = "78";
export const DAY = "79";
export const TOT_SALES = "80";
export const EXPENSES = "81";
export const PURCHASE_COST = "82";
export const PROFIT = "83";
