import React, { useState } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Dialog, DialogTitle } from '@material-ui/core';
import ReceiptPDF from './pdf/ReceiptPDF';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// const ReceiptPDF = (props) => {
//   return (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <View style={styles.section}>
//           <Text>Section #1</Text>
//         </View>
//         <View style={styles.section}>
//           <Text>Section #2</Text>
//         </View>
//       </Page>
//     </Document>
//   );
// };

// export default ReceiptPDF;

export function withDialog(Comp) {
  return (props) => {
    const [open, setOpen] = useState(true);
    const { close, ...restProps } = props;

    const handleClose = () => {
      setOpen(false);
      props.close(false);
    };

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Comp {...restProps} />
        <DialogTitle id="simple-dialog-title"></DialogTitle>
      </Dialog>
    );
  };
}

export const ReceiptPDFDialog = withDialog(ReceiptPDF);
