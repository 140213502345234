import ExpenseDto from "dto/ExpenseDto";

export default class ExpenseModel {
  public Id: string;

  public Date: number;

  public Billnum: number;

  public Type: number;

  public Amount: number;

  public Reference: string;

  public Notes: string;
}

export function ExpenseModelToDto(model: ExpenseModel) {
  const dto: ExpenseDto = new ExpenseDto();
  dto._d = model.Date;
  dto.am = model.Amount;
  dto.bn = model.Billnum;
  dto.id = model.Id;
  dto.n = model.Notes;
  dto.r = model.Reference;
  dto.t = model.Type;

  return dto;
}

export function ExpenseDtoToModel(dto: ExpenseDto) {
  const model: ExpenseModel = new ExpenseModel();
  model.Date = dto._d;
  model.Amount = dto.am;
  model.Billnum = dto.bn;
  model.Id = dto.id;
  model.Notes = dto.n;
  model.Reference = dto.r;
  model.Type = dto.t;

  return model;
}
